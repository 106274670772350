@use '../../styles/colors';
@use '../../styles/functions';
@use '../../styles/variables';
@import '../../styles/typography';

.rightContainer {
    display: flex;
    width: 40%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    z-index: 1;
    .formContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        // padding-top: 10%;
        position: static;
        align-items: flex-start;
    }
    .inputSection {
        width: 80%;
    }
    .selectSection {
        width: 80%;
        padding-top: 3%;
    }
    sup {
        color: red;
    }
    h1 {
        display: flex;
        flex-direction: column;
        // font-family: Segoe UI;
        font-family: 'Avenir', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 32px;
        text-transform: capitalize;
        color: #FFFFFF;
    }
    .light {
        color: #{colors.$black};
    }
    .lightSpan {
        color: #{colors.$grey-75};
    }
    .dark {
        color: #{colors.$white};
    }
    h1 span {
        // font-family: Segoe UI;
        font-family: 'Avenir', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        text-transform: capitalize;
        color: rgba(255, 255, 255, 0.58);
        padding: 20px 0px 0px 0px;
    }
    p {
        font-family: 'Avenir', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        text-align: justify;
        color: #FFFFFF;
    }
    .themeButton {
        margin-top: 2%;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-end;
    }
    .checkboxSection {
        margin-top: 2%;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .checkboxDescription {
            padding-left: 2%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
        }
        p {
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 0px;
            // margin: 5px auto;
            // color:rgba(255, 255, 255, 1);
        }
        span {
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 0px;
            // margin: 5px auto;
            color:rgba(255, 255, 255, 1);
        }
    }
    .firstCheckContainer {
        margin-top: 5%;
    }
    .buttonSection {
        width: 80%;
        margin-top: 5%;
        .button {
            width: 100%;
        }
    }
}

.loginForm {
    width: 100%;
    max-width: 460px;
}
// mobile
@media only screen and (max-width: 767px) {
    .rightContainer {
        display: flex;
        width: 100%;
    
        z-index: 1;
        .formContainer {
            display: flex;
            flex-direction: column;
            width: 90%;
            margin: auto;
            position: static;
            align-items: flex-start;
        }
        .inputSection {
            width: 100%;
        }
        .selectSection {
            width: 100%;
            padding-top: 3%;
        }
        sup {
            color: red;
        }
        h1 {
            display: flex;
            flex-direction: column;
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 32px;
            text-transform: capitalize;
            color: #FFFFFF;
        }
        .light {
            color: #{colors.$black};
        }
        .lightSpan {
            color: #{colors.$grey-75};
        }
        .dark {
            color: #{colors.$white};
        }
        h1 span {
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-transform: capitalize;
            color: rgba(255, 255, 255, 0.58);
            padding: 20px 0px 0px 0px;
        }
        p {
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-align: justify;
            color: #FFFFFF;
        }
        .themeButton {
            margin-top: 2%;
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: flex-end;
        }
        .checkboxSection {
            margin-top: 2%;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            .checkboxDescription {
                padding-left: 2%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
            }
            p {
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 0px;
                // margin: 5px auto;
                // color:rgba(255, 255, 255, 1);
            }
            span {
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 0px;
                // margin: 5px auto;
                color:rgba(255, 255, 255, 1);
            }
        }
        .firstCheckContainer {
            margin-top: 5%;
        }
        .buttonSection {
            width: 100%;
            margin-top: 5%;
            .button {
                width: 100%;
            }
        }
    }
}
// tablet
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rightContainer {
        display: flex;
        width: 40%;
        padding: 0;
        z-index: 1;
        .formContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            // padding-top: 10%;
            position: static;
            align-items: flex-start;
        }
        .inputSection {
            width: 100%;
        }
        .selectSection {
            width: 100%;
            padding-top: 3%;
        }
        sup {
            color: red;
        }
        h1 {
            display: flex;
            flex-direction: column;
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 32px;
            text-transform: capitalize;
            color: #FFFFFF;
        }
        .light {
            color: #{colors.$black};
        }
        .lightSpan {
            color: #{colors.$grey-75};
        }
        .dark {
            color: #{colors.$white};
        }
        h1 span {
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-transform: capitalize;
            color: rgba(255, 255, 255, 0.58);
            padding: 20px 0px 0px 0px;
        }
        p {
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-align: justify;
            color: #FFFFFF;
        }
        .themeButton {
            margin-top: 2%;
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: flex-end;
        }
        .checkboxSection {
            margin-top: 2%;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            .checkboxDescription {
                padding-left: 2%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
            }
            p {
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 0px;
                // margin: 5px auto;
                // color:rgba(255, 255, 255, 1);
            }
            span {
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 0px;
                // margin: 5px auto;
                color:rgba(255, 255, 255, 1);
            }
        }
        .firstCheckContainer {
            margin-top: 5%;
        }
        .buttonSection {
            width: 100%;
            margin-top: 5%;
            .button {
                width: 100%;
            }
        }
    }
}
$brand-blue: #00a3ff;
$brand-purple: #9e00ff;
$brand-red: #f10939;
$brand-orange: #fc9700;
$brand-gold: #EBAF00;

$white: #fff;
$black: #000;

$grey-100: #161616;
$grey-95: #1c1c1c;
$grey-90: #202020;
$grey-85: #252525;
$grey-80: #313131;
$grey-75: #4d4d4d;
$grey-70: #6c6c6c;
$grey-65: #898989;
$grey-60: #a8a8a8;
$grey-55: #c6c6c6;
$grey-50: #e0e0e0;
$grey-45: #f4f4f4;
$grey-5: #eeecf1;

$blue-100: #002133;
$blue-90: #004166;
$blue-80: #0676ED;
$blue-70: #0082cc;
$blue-60: #00a3ff;
$blue-50: #2bb2ff;
$blue-40: #55c2ff;
$blue-30: #80d1ff;
$blue-20: #aae0ff;
$blue-10: #d5f0ff;

$purple-100: #200033;
$purple-90: #3f0066;
$purple-80: #5f0099;
$purple-70: #7e00cc;
$purple-60: #9e00ff;
$purple-50: #ae2bff;
$purple-40: #be55ff;
$purple-30: #cf80ff;
$purple-20: #dfaaff;
$purple-10: #efd5ff;

$red-100: #30020b;
$red-90: #600417;
$red-80: #910522;
$red-70: #c1072e;
$red-60: #f10939;
$red-50: #f3325a;
$red-40: #f65b7b;
$red-30: #f8849c;
$red-20: #faadbd;
$red-10: #fdd6de;

$error-90: #300;
$error-80: #600;
$error-70: #900;
$error-60: #c00;
$error-50: #f00;
$error-40: #f33;
$error-30: #f66;
$error-20: #f99;
$error-10: #fcc;

$warning-90: #332600;
$warning-80: #664d00;
$warning-70: #997300;
$warning-60: #cc9a00;
$warning-50: #ffc000;
$warning-40: #ffcd33;
$warning-30: #ffd966;
$warning-20: #ffe699;
$warning-10: #fff2cc;

$success-90: #133300;
$success-80: #276600;
$success-70: #3a9900;
$success-60: #4ecc00;
$success-50: #61ff00;
$success-40: #81ff33;
$success-30: #a0ff66;
$success-20: #c0ff99;
$success-10: #dfffcc;

@use '../../styles/colors';
@use '../../styles/functions';
@use '../../styles/variables';
@import '../../styles/typography';

.outer {
    display: block;
    padding: 10%;
    height: 100vh;
}
.message {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    svg {
        width: 100px;
        display: block;
        margin: 40px auto 0;
    }
    
    .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
        &.circle {
            -webkit-animation: dash .9s ease-in-out;
            animation: dash .9s ease-in-out;
        }
        &.line {
            stroke-dashoffset: 1000;
            -webkit-animation: dash .9s .35s ease-in-out forwards;
            animation: dash .9s .35s ease-in-out forwards;
        }
        &.check {
            stroke-dashoffset: -100;
            -webkit-animation: dash-check .9s .35s ease-in-out forwards;
            animation: dash-check .9s .35s ease-in-out forwards;
        }
    }
    
    p {
        text-align: center;
        margin: 20px 0 60px;
        font-size: 1.25em;
        &.success {
            color: #73AF55;
        }
        &.error {
            color: #D06079;
        }
    }
    
    @-webkit-keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }
    
    @keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }
    
    @-webkit-keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }
    
    @keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }
}
p {
    margin-top: 2%;
    font-family: 'Avenir', sans-serif;
}
.light {
    color: #{colors.$black}
}
.dark {
    color: #{colors.$white}
}
.button {
    width: 30%;
}
@use '../../../styles/colors';
@use '../../../styles/functions';
@use '../../../styles/variables';
@import '../../../styles/typography.scss';
.root {
    // default variables
    --padding: #{functions.rem(2)} #{functions.rem(24)};
    --height: #{functions.rem(24)};
    --font-size: #{functions.rem(14)};
    --border-width: 0;
    --border-width-top: var(--border-width);
    --border-width-left: var(--border-width);
    --border-width-right: var(--border-width);
    --border-width-bottom: var(--border-width);
    --border-color: transparent;
    --background-color: transparent;
    --background-image: none;
    --border-radius: #{functions.rem(20)};
    --gradient: linear-gradient(
        110.42deg,
        #{colors.$brand-purple},
        #{colors.$brand-red}
    );
    --text-color: #{colors.$white};
    --text-color-light: #{colors.$black};
    --icon-size: #{functions.rem(18)};
}

.xs {
    --font-size: #{functions.rem(12)};
}

.sm {
    --height: #{functions.rem(32)};
}

.md {
    --height: #{functions.rem(36)};
}

.lg {
    --height: #{functions.rem(40)};
    --font-size: #{functions.rem(16)};
    --icon-size: #{functions.rem(24)};
}

.xl {
    --height: #{functions.rem(48)};
    --font-size: #{functions.rem(16)};
    --icon-size: #{functions.rem(24)};
}

.buttonFont {
    font-family: 'Avenir', sans-serif;
}

.customSize {
    --padding: #{functions.rem(2)};
}

.variantRegular {
    --gradient-background: none;
    --background-color: #{colors.$blue-80};
    &:hover {
        --background-color: #{colors.$blue-90};
    }
    &:active {
        --background-color: #{colors.$blue-70};
    }
    &.danger {
        --gradient-background: none;
        --background-color: #{colors.$red-60};

        // &:hover {
        //     --background-color: #{colors.$red-80};
        // }

        // &:active {
        //     --background-color: #{colors.$red-70};
        // }
    }

    &.disabled {
        --background-color: #{colors.$grey-85};
    }
}
.dark {
    color: #{colors.$white};
    &:hover {
        --background-color: #{colors.$grey-85};
    }

    &:active {
        --background-color: #{colors.$grey-75};
    }
    &.disabled {
        --background-color: #{colors.$grey-85};
    }
}
.light {
    color: #{colors.$black};
    &:hover {
        --background-color: #{colors.$grey-45};
    }

    &:active {
        --background-color: #{colors.$grey-5};
    }
    &.disabled {
        --background-color: #{colors.$grey-55};
    }
}
.variantBrand {
    --gradient-background: none;
    --background-color: #{colors.$brand-gold};
    color: #{colors.$black};
    
    &:hover {
        --background-color: #{colors.$brand-orange};
    }
    &:active {
        --background-color: #{colors.$warning-50};
    }
    &.danger {
        --gradient-background: none;
        --background-color: #{colors.$red-60};

        &:hover {
            --background-color: #{colors.$red-80};
        }

        &:active {
            --background-color: #{colors.$red-70};
        }
    }
}

.variantBrandOutline {
    --border-width: #{functions.rem(2)};
    --border-color: #{colors.$brand-gold};

    // &:hover {
    //     --background-color: #{colors.$grey-85};
    // }

    // &:active {
    //     --background-color: #{colors.$grey-75};
    // }

    &.danger {
        --gradient-background: transparent;
        --border-width: #{functions.rem(2)};
        --border-color: #{colors.$red-60};

        &:hover {
            --background-color: #{colors.$red-80};
        }

        &:active {
            --background-color: #{colors.$red-70};
        }
    }

    &.disabled {
        --border-width: #{functions.rem(2)};
        --border-color: #{colors.$grey-85};
    }
}
.variantOutline {
    --border-width: #{functions.rem(2)};
    --border-color: #{colors.$grey-5};

    

    &.danger {
        --gradient-background: transparent;
        --border-width: #{functions.rem(2)};
        --border-color: #{colors.$red-60};

        &:hover {
            --background-color: #{colors.$red-80};
        }

        &:active {
            --background-color: #{colors.$red-70};
        }
    }

    &.disabled {
        --border-width: #{functions.rem(2)};
        --border-color: #{colors.$grey-85};
    }
}

.variantMonochrome {
    --border-width: #{functions.rem(2)};
    --border-color: #{colors.$grey-75};

    // &:hover {
    //     --background-color: #{colors.$grey-85};
    // }

    // &:active {
    //     --background-color: #{colors.$grey-75};
    // }

    &.disabled {
        --border-width: #{functions.rem(2)};
        --border-color: #{colors.$grey-85};
    }
}

.variantClear {
    // &:hover {
    //     --background-color: #{colors.$grey-85};
    // }

    // &:active {
    //     --background-color: #{colors.$grey-75};
    // }
}

.disabled {
    --text-color: #{colors.$grey-75};
}

.root {
    align-items: center;
    background-color: var(--background-color);
    border-color: var(--border-color);
    border-radius: var(--border-radius);
    border-style: solid;
    border-width: var(--border-width-top) var(--border-width-right)
        var(--border-width-bottom) var(--border-width-left);
    // color: var(--text-color);
    cursor: pointer;
    display: flex;
    font-size: var(--font-size);
    font-weight: 600;
    height: var(--height);
    justify-content: center;
    line-height: functions.rem(24);
    min-width: var(--height);
    outline: none;
    overflow: hidden;
    padding: var(--padding);
    position: relative;
    text-decoration: none;
    transition: all 0.3s;
    user-select: none;
    white-space: nowrap;
    z-index: 1;

    svg {
        // color: var(--text-color);
        display: block;
        height: var(--icon-size);
        transition: inherit;
        width: var(--icon-size);
    }
}

.variantOutline {
    border-color: var(--border-color);
    &::before,
    &::after {
        content: '';
        display: block;
    }

    &::before {
        background-image: var(--gradient-background);
        border-radius: inherit;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: inherit;
        z-index: 0;
    }
}

.variantMonochrome {
    border-width: var(--border-width-top) var(--border-width-right)
        var(--border-width-bottom) var(--border-width-left);
}

.disabled {
    cursor: default;
    pointer-events: none;

    &::before {
        display: none;
    }

    &::after {
        display: none;
    }
}

.leftElement,
.rightElement,
.content {
    position: relative;
    transition: inherit;
    z-index: 1;
}

.uppercase {
    text-transform: uppercase;
}

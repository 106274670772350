@use '../../../styles/colors';
@use '../../../styles/functions';
@use '../../../styles/variables';
@import '../../../styles/typography.scss';
.toasterContainer {
    display: flex;
    flex-direction: column;
}
.headerContainer{
    display: flex;
    flex: row;
    width: 100%;
    justify-content: space-between;
    .header {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2%;
        font-family: 'Avenir', sans-serif;
        font-weight: bold;
        text-align: left;
    }
    .closeButton {
        width: 10%;
        text-align: right;
        cursor: pointer;
    }
}

.light {
    color: #{colors.$black}
}
.dark {
    color: #{colors.$white}
}
@use '../../styles/colors';
@use '../../styles/functions';
@use '../../styles/variables';
@import '../../styles/typography';
.leftContainer {
    --padding: #{functions.rem(2)} #{functions.rem(24)};
    --height: #{functions.rem(24)};
    --font-size: #{functions.rem(14)};
    --border-width: 0;
    --border-width-top: var(--border-width);
    --border-width-left: var(--border-width);
    --border-width-right: var(--border-width);
    --border-width-bottom: var(--border-width);
    --border-color: #{colors.$brand-gold};
    --background-color: #{colors.$black};
    --background-image: none;
    --border-radius: #{functions.rem(0.6)};
    --gradient: linear-gradient(
        110.42deg,
        #{colors.$brand-purple},
        #{colors.$brand-red}
    );
    --text-color: #{colors.$white};
    --icon-size: #{functions.rem(18)};
    
}
.stepperContainer {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    margin-top: 5%;
    
    .stepper {
        display: flex; 
        flex-direction: row;
        width: 60%; 
        min-height: 30px;
        margin: 5px; 
        justify-content: left; 
        align-items: center; 
        cursor: pointer;
        border-radius: 20px;
        .stepIcon{
            display: flex; 
            flex-direction: row;
            align-items: center; 
            justify-content: center;
        }
        .stepName {
            // color: #{colors.$grey-5}; 
            width: 70%; 
            text-align: left;
        }
        .stepDark {
            color: #{colors.$grey-5}; 
        }
        .stepLight {
            color: #{colors.$grey-70}; 
        }
    }
    .activeStepper {
        background: #{colors.$brand-gold};
        color:  #{colors.$black};
        display: flex; 
        flex-direction: row;
        width: 60%; 
        min-height: 30px;
        margin: 5px; 
        justify-content: left; 
        align-items: center; 
        cursor: pointer;
        .stepName {
            color: #{colors.$grey-100}; 
            width: 70%;
            border-radius: 20px;
            text-align: left;
        }
    }
}
.container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}
.leftContainer {
    // background: var(--background-color);
    width: 30%;

    overflow-y: auto; /* Ensures this part doesn't scroll */
    //padding-bottom: 5%;
    // overflow: hidden;
    .content {
        display: flex;
        flex-direction: column;

        align-items: flex-start;
    }
    .headerImage {
        padding: 0 32px 15px 0;
        width: 355px;
        height: auto;
        // margin-left: -100px;
    }
    .header { 
        font-size: 32.858px;
        line-height: 101.51%; 
        width: 278px; 
        font-style: normal;
        font-family: 'Avenir', sans-serif;
        font-weight: 600;
        font-size: 26px;
        letter-spacing: normal;
        text-transform: uppercase; 
        color: var(--text-color);
        margin-top: 14px;
        margin-left: 6px;
        text-align: left;
    }
    .secondHeader {
        width: 278px;
        font-style: normal;
        font-weight: normal;
        font-family: 'Avenir', sans-serif;
        font-size: 14px;
        line-height: 101.51%;
        // letter-spacing: 0.56em;
        letter-spacing: normal;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.73);
        margin-top: 10px;
        margin-left: 6px;
        text-align: left;
    }
    p {
        font-family: 'Avenir', sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 164.01%;
        color: var(--text-color);
        letter-spacing: 0.07em;
    }
    .counter {
        height: 15vh;
        display: flex;
        align-items: center;
        width: 275px;// to align to left
        .round {
            color: var(--text-color);
            font-family: 'Avenir', sans-serif;
            font-size: 14px;
            background: #222B45;
            border: 1.1735px solid var(--border-color);
            box-sizing: border-box;
            box-shadow: 0px 14.082px 28.164px -4.69401px rgb(0 0 0 / 25%);
            border-radius: 6.082px;
            margin-right: 20px;
            position: relative;
            z-index: 2;
            height: 23px;
            width: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            // border-radius: 50%;
            // background: linear-gradient(27deg, rgba(173, 129, 12, 1) 29%, rgba(248, 215, 108, 1) 53%, rgba(160, 123, 20, 1) 81%);
        }
        .line:before {
            color: var(--text-color);
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 50%;
            width: 1px;
            height: 90px;
            background-image: linear-gradient(0deg, #ffffff00, #ffffff);
            border: 1.1735px solid var(--border-color);
            transform: rotate(180deg);

        }
    }
}
.rightContainer {
    width: 70%;
    overflow-y: auto;
    max-height: 100vh;
    padding-bottom: 5%;
}
.darkFont {
    color: #{colors.$white};
}
.lightFont {
    color: #{colors.$black};
}
.logout {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-left: 5%;
    padding-right: 10%;
    padding-top: 5%;
    align-items: center;
}
.headerSection {
    width: 80%;
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 10%;
    margin-top: 3%;
    justify-content: space-between;
    .header {
        // color: #{colors.$white};
        @extend %title-2;
    }
    .buttons {
        display: flex;
        flex-direction: row;
    }
    .button {
        margin-left: 1rem;
    }
}

.footerSection {
    width: 80%;
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 10%;
    margin-top: 3%;
    justify-content: space-between;
}
.alignRight {
    justify-content: flex-end;
}
.hide {
    display: none;
}
.buttonContainer {
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    
}

// mobile
@media only screen and (max-width: 767px) {
    .container {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    .leftContainer {
        // background: var(--background-color);
        width: 100%;
        //height: fit-content;
        // overflow-y: hidden; 
        
    }
    .rightContainer {
        width: 100%;
        overflow-y: auto;
        max-height: 100vh;
        padding-bottom: 5%;
    }
    .logout {
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-left: 5%;
        padding-right: 10%;
        padding-top: 5%;
        align-items: center;
    }
    .headerSection {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
        margin-top: 3%;
        justify-content: space-around;
        
        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 5%;
        }
        .button {
            margin-left: 1rem;
        }
    }
}
// tablet
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .leftContainer {
        .headerImage {
            width: 200px;
        }
    }
}
@use '../../../styles/colors';
@use '../../../styles/functions';
@use '../../../styles/variables';
@import '../../../styles/typography';

.rightContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-left: 5%;
    padding-right: 10%;
    padding-top: 3%;
    z-index: 1;
    .middleName {
        // width: fit-content;
        text-align: left;
    }
    .errorText {
        color: red;
        width: 100%;
        text-align: center;
    }
    .darkFont {
        color: #{colors.$white};
    }
    .lightFont {
        color: #{colors.$black};
    }
    .smallerFont {
        font-size: smaller;
    }
    .formContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        // padding-top: 10%;
        position: static;
        align-items: center;
        gap: 10px;
    }
    .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: baseline;
        justify-content: space-between;
    }
    .rowToCenter {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: baseline;
        justify-content: center;
    }
    .rowToEnd {
        justify-content: flex-end;
    }
    .inputSection {
        width: 30%;
        margin-right: 15px;
    }
    .subHeader {
        @extend %title-3;
        // color: #{colors.$grey-55};
    }
    .inputSectionHalf {
        min-width: 50%;
        margin-right: 15px;
    }
    .inputSectionFull {
        width: 100%;
        margin-right: 15px;
    }
    .noTitle {
        margin-top: 1rem;
    }
    .currencySelectContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .currency {
            width: 9rem
        }
        .currencyWallet {
            min-width: 13rem
        }
        .currencySymbol {
            display: flex;
            height: 100%;
            align-self: center;
        }
    }
    .checkboxGrid {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        .checkbox {
            flex: 50%;
            gap: 0.5rem;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-align: justify;
            
            margin-bottom: 0.2rem;
            display: flex;
            flex-direction: row;
            align-items: baseline;
        }
        .darkFont {
            color: #{colors.$grey-55};
        }
        .lightFont {
            color: #{colors.$grey-80};
        }
    }

    sup {
        color: red;
    }
    h1 {
        display: flex;
        flex-direction: column;
        // font-family: Segoe UI;
        font-family: 'Avenir', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 32px;
        text-transform: capitalize;
        // color: #FFFFFF;
    }
    h1 span {
        // font-family: Segoe UI;
        font-family: 'Avenir', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        text-transform: capitalize;
        color: rgba(255, 255, 255, 0.58);
        padding: 20px 0px 0px 0px;
    }
    p {
        font-family: 'Avenir', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        text-align: justify;
        // color: #FFFFFF;
        margin-bottom: 0.2rem;
    }
    .checkboxSection {
        margin-top: 2%;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .checkboxDescription {
            padding-left: 2%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
        }
        p {
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 0px;
            // margin: 5px auto;
            color: #{colors.$white};
        }
        span {
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 0px;
            // margin: 5px auto;
            color: #{colors.$white};
        }
    }
    .firstCheckContainer {
        margin-top: 5%;
    }
    .addMargin {
        margin-top: 2%;
    }
    .addLessMargin {
        margin-top: 1%;
    }
    .addMoreMargin {
        margin-top: 4%;
    }
    .toRight {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .buttonSection {
        width: 80%;
        margin-top: 5%;
        .button {
            width: 100%;
        }
    }
    .sectionDivider {
        margin-top: 1%;
        border-color:  #{colors.$grey-45};
        border-width: 0.1rem; 
        width: 100%;
    }
    .dark {
        border-color: #{colors.$grey-55};
        background-color: #{colors.$grey-80};
    }
    .light {
        border-color: #{colors.$grey-95};
        background-color: #f4f4f4;
    }
    .imageUploader {
        position: relative;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        height: 200px;
        border-width: 1px;
        border-style: dotted;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .innerContainer {
            align-self: center; 
            display: flex;
            flex-direction: row;
        }
        .darkFont {
            color: #{colors.$grey-55};
        }
        .lightFont {
            color: #{colors.$grey-85};
        }
        .description {
            @extend %title-4;
            
        }
        .icon {
            // color: #{colors.$grey-55};
            align-self: center
        }
        .subDescription {
            @extend %title-5;
            // color: #{colors.$grey-55};
        }
        // .loading {
        //     align-self: center; 
        //     margin-bottom: 2%;
            
        //     // color:#{colors.$grey-55};
        // }
        .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .image {
            max-height: 100%;   /* Maximum height of image is 100% of its container */
            width: auto;        /* Width adjusts to maintain the aspect ratio */
            object-fit: contain; /* Ensures the image fits within the container without cropping */
            display: block;

            // max-height: 100%; /* Make the image's height not exceed the container's height */
            // //max-width: 100%; /* Make the image's width not exceed the container's width */
            // object-fit: cover;
            @extend %title-5;
            // color: #{colors.$grey-55};
        }
    }
    .declarationTxt {
        text-align: start;
        @extend %body-2;
        // color: #{colors.$grey-55};
    }
}

.loginForm {
    width: 100%;
    max-width: 460px;
}

// mobile
@media only screen and (max-width: 767px) {
    .rightContainer {
        display: flex;
        flex-direction: column;
        width: 90%;
        padding: 0;
        padding-left: 5%;
        padding-right: 5%;
        // padding-top: 3%;
        z-index: 1;
        .darkFont {
            color: #{colors.$white};
        }
        .lightFont {
            color: #{colors.$black};
        }
        .formContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            // padding-top: 10%;
            position: static;
            align-items: center;
        }
        .row {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: baseline;
            justify-content: space-between;
            gap: 15px;
        }
        .rowToCenter {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: baseline;
            justify-content: center;
        }
        .rowToEnd {
            justify-content: flex-end;
        }
        .inputSection {
            width: 100%;
            //margin-right: 15px;
        }
        // .subHeader {
        //     @extend %title-3;
        //     // color: #{colors.$grey-55};
        // }
        .inputSectionHalf {
            width: 100%;
            //margin-right: 15px;
        }
        .inputSectionFull {
            width: 100%;
            //margin-right: 15px;
        }
        .noTitle {
            margin-top: 1rem;
        }
        .currencySelectContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            .currency {
                width: 9rem
            }
            .currencyWallet {
                min-width: 13rem
            }
            .currencySymbol {
                display: flex;
                height: 100%;
                align-self: center;
            }
        }
        .checkboxGrid {
            display: flex;
            align-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            .checkbox {
                flex: 50%;
                gap: 0.5rem;
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 16px;
                text-align: justify;
                
                margin-bottom: 0.2rem;
                display: flex;
                flex-direction: row;
                align-items: baseline;
            }
            .darkFont {
                color: #{colors.$grey-55};
            }
            .lightFont {
                color: #{colors.$grey-80};
            }
        }
    
        sup {
            color: red;
        }
        h1 {
            display: flex;
            flex-direction: column;
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 32px;
            text-transform: capitalize;
            // color: #FFFFFF;
        }
        h1 span {
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-transform: capitalize;
            color: rgba(255, 255, 255, 0.58);
            padding: 20px 0px 0px 0px;
        }
        p {
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-align: justify;
            // color: #FFFFFF;
            margin-bottom: 0.2rem;
        }
        .checkboxSection {
            margin-top: 2%;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            .checkboxDescription {
                padding-left: 2%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
            }
            p {
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 0px;
                // margin: 5px auto;
                color: #{colors.$white};
            }
            span {
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 0px;
                // margin: 5px auto;
                color: #{colors.$white};
            }
        }
        .firstCheckContainer {
            margin-top: 5%;
        }
        .addMargin {
            margin-top: 2%;
        }
        .addLessMargin {
            margin-top: 1%;
        }
        .addMoreMargin {
            margin-top: 4%;
        }
        .toRight {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
        .buttonSection {
            width: 80%;
            margin-top: 5%;
            .button {
                width: 100%;
            }
        }
        .sectionDivider {
            margin-top: 1%;
            border-color:  #{colors.$grey-45};
            border-width: 0.1rem; 
            width: 100%;
        }
        .dark {
            border-color: #{colors.$grey-55};
            background-color: #{colors.$grey-80};
        }
        .light {
            border-color: #{colors.$grey-95};
            background-color: #f4f4f4;
        }
        .imageUploader {
            position: relative;
            margin: auto;
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
            height: 200px;
            border-width: 1px;
            border-style: dotted;
            display: flex;
            flex-direction: column;
            justify-content: center;
    
            .innerContainer {
                align-self: center; 
                display: flex;
                flex-direction: row;
            }
            .darkFont {
                color: #{colors.$grey-55};
            }
            .lightFont {
                color: #{colors.$grey-85};
            }
            // .description {
            //     @extend %title-4;
                
            // }
            .icon {
                // color: #{colors.$grey-55};
                align-self: center
            }
            // .subDescription {
            //     @extend %title-5;
            //     // color: #{colors.$grey-55};
            // }
            // .loading {
            //     align-self: center; 
            //     margin-bottom: 2%;
                
            //     // color:#{colors.$grey-55};
            // }
            .loading {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            // .image {
            //     max-height: 100%;   /* Maximum height of image is 100% of its container */
            //     width: auto;        /* Width adjusts to maintain the aspect ratio */
            //     object-fit: contain; /* Ensures the image fits within the container without cropping */
            //     display: block;
    
            //     // max-height: 100%; /* Make the image's height not exceed the container's height */
            //     // //max-width: 100%; /* Make the image's width not exceed the container's width */
            //     // object-fit: cover;
            //     @extend %title-5;
            //     // color: #{colors.$grey-55};
            // }
        }
        // .declarationTxt {
        //     text-align: start;
        //     @extend %body-2;
        //     // color: #{colors.$grey-55};
        // }
    }
    
    .loginForm {
        width: 100%;
        max-width: 460px;
    }
}
// tablet
@media only screen and (min-width: 768px) and (max-width: 1268px) {
    .rightContainer {
        display: flex;
        flex-direction: column;
        width: 80%;
        padding-left: 5%;
        padding-right: 10%;
        padding-top: 3%;
        z-index: 1;
        .darkFont {
            color: #{colors.$white};
        }
        .lightFont {
            color: #{colors.$black};
        }
        .formContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            // padding-top: 10%;
            position: static;
            align-items: center;
        }
        .row {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: baseline;
            justify-content: space-between;
        }
        .rowToCenter {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: baseline;
            justify-content: center;
        }
        .rowToEnd {
            justify-content: flex-end;
        }
        .inputSection {
            width: 50%;
            margin-right: 15px;
        }
        // .subHeader {
        //     @extend %title-3;
        //     // color: #{colors.$grey-55};
        // }
        .inputSectionHalf {
            min-width: 50%;
            margin-right: 15px;
        }
        .inputSectionFull {
            width: 100%;
            margin-right: 15px;
        }
        .noTitle {
            margin-top: 1rem;
        }
        .currencySelectContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            .currency {
                min-width: 9rem
            }
            .currencyWallet {
                min-width: 13rem
            }
            .currencySymbol {
                display: flex;
                height: 100%;
                align-self: center;
            }
        }
        .checkboxGrid {
            display: flex;
            align-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            .checkbox {
                flex: 50%;
                gap: 0.5rem;
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 16px;
                text-align: justify;
                
                margin-bottom: 0.2rem;
                display: flex;
                flex-direction: row;
                align-items: baseline;
            }
            .darkFont {
                color: #{colors.$grey-55};
            }
            .lightFont {
                color: #{colors.$grey-80};
            }
        }
    
        sup {
            color: red;
        }
        h1 {
            display: flex;
            flex-direction: column;
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 32px;
            text-transform: capitalize;
            // color: #FFFFFF;
        }
        h1 span {
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-transform: capitalize;
            color: rgba(255, 255, 255, 0.58);
            padding: 20px 0px 0px 0px;
        }
        p {
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-align: justify;
            // color: #FFFFFF;
            margin-bottom: 0.2rem;
        }
        .checkboxSection {
            margin-top: 2%;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            .checkboxDescription {
                padding-left: 2%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 100%;
            }
            p {
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 0px;
                // margin: 5px auto;
                color: #{colors.$white};
            }
            span {
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 0px;
                // margin: 5px auto;
                color: #{colors.$white};
            }
        }
        .firstCheckContainer {
            margin-top: 5%;
        }
        .addMargin {
            margin-top: 2%;
        }
        .addLessMargin {
            margin-top: 1%;
        }
        .addMoreMargin {
            margin-top: 4%;
        }
        .toRight {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
        .buttonSection {
            width: 80%;
            margin-top: 5%;
            .button {
                width: 100%;
            }
        }
        .sectionDivider {
            margin-top: 1%;
            border-color:  #{colors.$grey-45};
            border-width: 0.1rem; 
            width: 100%;
        }
        .dark {
            border-color: #{colors.$grey-55};
            background-color: #{colors.$grey-80};
        }
        .light {
            border-color: #{colors.$grey-95};
            background-color: #f4f4f4;
        }
        .imageUploader {
            position: relative;
            margin: auto;
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
            height: 200px;
            border-width: 1px;
            border-style: dotted;
            display: flex;
            flex-direction: column;
            justify-content: center;
    
            .innerContainer {
                align-self: center; 
                display: flex;
                flex-direction: row;
            }
            .darkFont {
                color: #{colors.$grey-55};
            }
            .lightFont {
                color: #{colors.$grey-85};
            }
            // .description {
            //     @extend %title-4;
                
            // }
            .icon {
                // color: #{colors.$grey-55};
                align-self: center
            }
            // .subDescription {
            //     @extend %title-5;
            //     // color: #{colors.$grey-55};
            // }
            // .loading {
            //     align-self: center; 
            //     margin-bottom: 2%;
                
            //     // color:#{colors.$grey-55};
            // }
            .loading {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            // .image {
            //     max-height: 100%;   /* Maximum height of image is 100% of its container */
            //     width: auto;        /* Width adjusts to maintain the aspect ratio */
            //     object-fit: contain; /* Ensures the image fits within the container without cropping */
            //     display: block;
    
            //     // max-height: 100%; /* Make the image's height not exceed the container's height */
            //     // //max-width: 100%; /* Make the image's width not exceed the container's width */
            //     // object-fit: cover;
            //     @extend %title-5;
            //     // color: #{colors.$grey-55};
            // }
        }
        // .declarationTxt {
        //     text-align: start;
        //     @extend %body-2;
        //     // color: #{colors.$grey-55};
        // }
    }
    
    .loginForm {
        width: 100%;
        max-width: 460px;
    }
}
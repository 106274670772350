@use '../../styles/colors';
@use '../../styles/functions';
@use '../../styles/variables';

.outer {
    --padding: #{functions.rem(2)} #{functions.rem(24)};
    --height: #{functions.rem(24)};
    --font-size: #{functions.rem(14)};
    --border-width: 0;
    --border-width-top: var(--border-width);
    --border-width-left: var(--border-width);
    --border-width-right: var(--border-width);
    --border-width-bottom: var(--border-width);
    --border-color: transparent;
    --background-color: #{colors.$black};
    --background-image: none;
    --border-radius: #{functions.rem(0.6)};
    --gradient: linear-gradient(
        110.42deg,
        #{colors.$brand-purple},
        #{colors.$brand-red}
    );
    --text-color: #{colors.$white};
    --icon-size: #{functions.rem(18)};
}

.outer {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    h2 {
        font-size: var(--font-size);
    }
    h1,h2,h3 {
        color: var(--text-color);
    }
}
// mobile
@media only screen and (max-width: 767px) {
    .outer {
        width: 100%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            font-size: var(--font-size);
        }
        h1,h2,h3 {
            color: var(--text-color);
        }
    }
}
// tablet
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .outer {
        width: 100%;
        min-height: 100%;
        display: flex;
        align-items: center;
        h2 {
            font-size: var(--font-size);
        }
        h1,h2,h3 {
            color: var(--text-color);
        }
    }
}

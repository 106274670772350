@use '../../../styles/colors';
@use '../../../styles/functions';
@use '../../../styles/variables';
@import '../../../styles/typography.scss';

h2 {
    font-family: 'Avenir', sans-serif;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    // font-size: 16px;
}
.description {
    font-family: 'Avenir', sans-serif;
    font-size: 16px;
    text-align: center;
}
.dark {
    color: #{colors.$white}
}
.light {
    color: #{colors.$black}
}
.closeButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    width: 98%;
    padding: 1%;
    height: fit-content;
}
.modalContainer {
    padding: 0;
    width: 40%;
    .icon {
        width: 100px;
        display: block;
        margin: 10px auto 0;
    }
    .content {
        padding: 10px;
        padding-bottom: 32px;
    }
    .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
        &.circle {
            -webkit-animation: dash .9s ease-in-out;
            animation: dash .9s ease-in-out;
        }
        &.line {
            stroke-dashoffset: 1000;
            -webkit-animation: dash .9s .35s ease-in-out forwards;
            animation: dash .9s .35s ease-in-out forwards;
        }
        &.check {
            stroke-dashoffset: -100;
            -webkit-animation: dash-check .9s .35s ease-in-out forwards;
            animation: dash-check .9s .35s ease-in-out forwards;
        }
    }
    
    p {
        text-align: center;
        margin: 20px 0 60px;
        font-size: 1.25em;
        &.success {
            color: #73AF55;
        }
        &.error {
            color: #D06079;
        }
    }
    
    @-webkit-keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }
    
    @keyframes dash {
        0% {
            stroke-dashoffset: 1000;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }
    
    @-webkit-keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }
    
    @keyframes dash-check {
        0% {
            stroke-dashoffset: -100;
        }
        100% {
            stroke-dashoffset: 900;
        }
    }
    
}

@use '../../styles/colors';
@use '../../styles/functions';
@use '../../styles/variables';
@import '../../styles/typography';

.rightContainer {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10%;
    z-index: 1;
    .themeContainer{
        padding-top: 0;  
    }
    .formContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        // padding-top: 10%;
        position: static;
        align-items: flex-start;
    }
    .inputSection {
        width: 80%;
    }
    sup {
        color: red;
    }
    h1 {
        display: flex;
        flex-direction: column;
        // font-family: Segoe UI;
        font-family: 'Avenir', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 32px;
        // text-transform: capitalize;
        // color: #FFFFFF;
    }
    .link {
        cursor: pointer;
        text-decoration: underline;
    }
    .light {
        color: #{colors.$black};
    }
    .lightSpan {
        color: #{colors.$grey-75};
    }
    .dark {
        color: #{colors.$white};
    }

    h1 span {
        // font-family: Segoe UI;
        font-family: 'Avenir', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 18px;
        //text-transform: capitalize;
        color: rgba(255, 255, 255, 0.58);
        padding: 20px 0px 0px 0px;
    }
    p {
        font-family: 'Avenir', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 18px;
        text-align: justify;
        // color: #FFFFFF;
    }
    .checkboxSection {
        margin-top: 2%;
        display: flex;
        flex-direction: row; // Changed from row to column for better mobile view
        align-items: flex-start; // Changed to align items to the start
        width: 100%;
        .checkboxDescription {
            display: block; // Changed from flex to block for better control
            width: 100%;
            margin-left: 1%;
        }
        p {
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 1.4; // Increased line height for better readability
            text-align: left; // Align text to the left for consistency
            word-wrap: break-word; // Ensure long words do not overflow
            hyphens: auto;  
            white-space: normal; // Allow normal wrapping
            overflow-wrap: break-word; // Ensure any overflow text is wrapped
        }
        a {
            color: inherit; // Ensure links use the same color as text for consistency
            text-decoration: underline; // Underline to denote links
        }
    }
    // .checkboxSection {
    //     margin-top: 2%;
    //     display: flex;
    //     flex-direction: row;
    //     align-items: baseline;
    //     width: 100%;
    //     .checkboxDescription {
    //         padding-left: 2%;
    //         display: flex;
    //         flex-direction: row;
    //         align-items: flex-start;
    //         width: 100%;
    //     }
    //     p {
    //         font-family: 'Avenir', sans-serif;
    //         font-style: normal;
    //         font-weight: normal;
    //         font-size: 14px;
    //         line-height: 0px;
    //         text-align: center;
    //         // margin: 5px auto;
    //         // color:rgba(255, 255, 255, 1);
    //     }
    //     span {
    //         font-family: 'Avenir', sans-serif;
    //         font-style: normal;
    //         font-weight: normal;
    //         font-size: 14px;
    //         line-height: 0px;
    //         // margin: 5px auto;
    //         color:rgba(255, 255, 255, 1);
    //     }
    // }
    .firstCheckContainer {
        margin-top: 5%;
    }
    .buttonSection {
        width: 80%;
        margin-top: 5%;
        .button {
            width: 100%;
        }
    }
}

.loginForm {
    width: 100%;
    max-width: 460px;
}
// mobile
@media only screen and (max-width: 767px) {
    .rightContainer {
        display: flex;
        flex-direction: column;
        width: 90%;
        height: 100%;
        // padding-left: 5%;
        padding: 0;
        padding-top: 10%;
        padding-left: 2%;
        z-index: 1;
        .themeContainer{
            padding-top: 0;  
        }
        .formContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            // padding-top: 10%;
            position: static;
            align-items: flex-start;
        }
        .inputSection {
            width: 100%;
        }
        sup {
            color: red;
        }
        h1 {
            display: flex;
            flex-direction: column;
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 32px;
            // text-transform: capitalize;
            // color: #FFFFFF;
        }
        .link {
            cursor: pointer;
            text-decoration: underline;
        }
        .light {
            color: #{colors.$black};
        }
        .lightSpan {
            color: #{colors.$grey-75};
        }
        .dark {
            color: #{colors.$white};
        }
    
        h1 span {
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            //text-transform: capitalize;
            color: rgba(255, 255, 255, 0.58);
            padding: 20px 0px 0px 0px;
        }
        p {
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-align: justify;
            // color: #FFFFFF;
        }.checkboxSection {
            margin-top: 2%;
            display: flex;
            flex-direction: row; // Changed from row to column for better mobile view
            align-items: flex-start; // Changed to align items to the start
            width: 100%;
            .checkboxDescription {
                display: block; // Changed from flex to block for better control
                width: 100%;
            }
            p {
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 1.4; // Increased line height for better readability
                text-align: left; // Align text to the left for consistency
                word-wrap: break-word; // Ensure long words do not overflow
                hyphens: auto;  
                white-space: normal; // Allow normal wrapping
                overflow-wrap: break-word; // Ensure any overflow text is wrapped
            }
            a {
                color: inherit; // Ensure links use the same color as text for consistency
                text-decoration: underline; // Underline to denote links
            }
        }
        .firstCheckContainer {
            margin-top: 5%;
        }
        .buttonSection {
            width: 100%;
            margin-top: 5%;
            .button {
                width: 100%;
            }
        }
    }
}

// tablet
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .rightContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        // padding-left: 5%;
        padding: 0;
        // padding-top: 10%;
        // padding-left: 2%;
        z-index: 1;
        .themeContainer{
            padding-top: 0;  
        }
        .formContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            // padding-top: 10%;
            position: static;
            align-items: flex-start;
        }
        .inputSection {
            width: 100%;
        }
        sup {
            color: red;
        }
        h1 {
            display: flex;
            flex-direction: column;
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 32px;
            // text-transform: capitalize;
            // color: #FFFFFF;
        }
        .link {
            cursor: pointer;
            text-decoration: underline;
        }
        .light {
            color: #{colors.$black};
        }
        .lightSpan {
            color: #{colors.$grey-75};
        }
        .dark {
            color: #{colors.$white};
        }
    
        h1 span {
            // font-family: Segoe UI;
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            //text-transform: capitalize;
            color: rgba(255, 255, 255, 0.58);
            padding: 20px 0px 0px 0px;
        }
        p {
            font-family: 'Avenir', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            text-align: justify;
            // color: #FFFFFF;
        }
        .checkboxSection {
            margin-top: 2%;
            display: flex;
            flex-direction: row; // Changed from row to column for better mobile view
            align-items: flex-start; // Changed to align items to the start
            width: 100%;
            .checkboxDescription {
                display: block; // Changed from flex to block for better control
                width: 100%;
            }
            p {
                font-family: 'Avenir', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 1.4; // Increased line height for better readability
                text-align: left; // Align text to the left for consistency
                word-wrap: break-word; // Ensure long words do not overflow
                hyphens: auto;  
                white-space: normal; // Allow normal wrapping
                overflow-wrap: break-word; // Ensure any overflow text is wrapped
            }
            a {
                color: inherit; // Ensure links use the same color as text for consistency
                text-decoration: underline; // Underline to denote links
            }
        }
        .firstCheckContainer {
            margin-top: 5%;
        }
        .buttonSection {
            width: 100%;
            margin-top: 5%;
            .button {
                width: 100%;
            }
        }
    }
}
@use '../../../styles/colors';
.root {
  // default variables
  --font-color: #{colors.$white};
  --color: #{colors.$brand-gold};
}

.radio {
  &.MuiRadio-colorPrimary {
    color: var(--color),
  }

  &.MuiRadio-colorPrimary.Mui-checked {
      color: var(--color),
  }
}
.label {
  color: var(--font-color)
}
.themeContainer{
    height: 100vh;
    display: flex;
}
.theme {
    margin-top: 10%;
}
// mobile
@media only screen and (max-width: 767px) {
    .themeContainer{
        height: fit-content;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .theme {
        margin-top: 5%;
    }
}
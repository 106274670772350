.root {
    display: block;
    padding: 20px;
    width: 100%;
    h2 {
        font-size: 20px;
    }
    h1,h2,h3 {
        color: white
    }
}

.block {
    margin: 20px 0;
}

.col {
    align-items: center;
    display: inline-flex;
    flex-direction: column;

    + .col {
        margin-left: 10px;
    }
}

.button + .button {
    margin-top: 10px;
}

.iconTable {
    border: 1px solid white;
    margin: auto;
}

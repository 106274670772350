// Breakpoints

// mobile <
$breakpoint-sm: 768;
// >= tablet-portrait <
$breakpoint-md: 960;
// >= tablet-landscape <
$breakpoint-lg: 1280;
// >= desktop

// Fonts

$base-font-size: 16;

$main-font: 'Avenir', sans-serif;
$title-font: 'Avenir', sans-serif;
$roboto-font: 'Avenir', sans-serif;


@use '../../styles/colors';
@use '../../styles/functions';
@use '../../styles/variables';
@import '../../styles/typography';
.leftContainer {
    --padding: #{functions.rem(2)} #{functions.rem(24)};
    --height: #{functions.rem(24)};
    --font-size: #{functions.rem(14)};
    --border-width: 0;
    --border-width-top: var(--border-width);
    --border-width-left: var(--border-width);
    --border-width-right: var(--border-width);
    --border-width-bottom: var(--border-width);
    --border-color: #{colors.$brand-gold};
    --background-color: #{colors.$black};
    --background-image: none;
    --border-radius: #{functions.rem(0.6)};
    --gradient: linear-gradient(
        110.42deg,
        #{colors.$brand-purple},
        #{colors.$brand-red}
    );
    --text-color: #{colors.$white};
    --icon-size: #{functions.rem(18)};
    
}

.leftContainer {
    // background: var(--background-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 3%;
    padding-left: 18%;
    // overflow: hidden;
    .content {
        display: flex;
        flex-direction: column;

        align-items: flex-start;
    }
    .headerImage {
        padding: 0 32px 15px 0;
        width: 380px;
        height: auto;
        margin-left: -100px;
    }
    .headerImageLight {
        padding: 0 32px 15px 0;
        width: 380px;
        height: auto;
        margin-left: -100px;
    }
    .header { 
        font-size: 32.858px;
        line-height: 101.51%; 
        width: 278px; 
        font-style: normal;
        font-family: 'Avenir', sans-serif;
        font-weight: 600;
        font-size: 26px;
        letter-spacing: normal;
        //text-transform: uppercase; 
        margin-top: 14px;
        margin-left: 6px;
        text-align: left;
    }
    .light {
        color: #{colors.$black};
    }
    .dark {
        color: #{colors.$white};
    }
    .secondHeader {
        width: 278px;
        font-style: normal;
        font-weight: normal;
        font-family: 'Avenir', sans-serif;
        font-size: 14px;
        line-height: 101.51%;
        // letter-spacing: 0.56em;
        letter-spacing: normal;
        // text-transform: uppercase;
        
        margin-top: 10px;
        margin-left: 6px;
        text-align: left;
    }
    .secondDark {
        color: rgba(255, 255, 255, 0.73);
    }
    .secondLight {
        color: #{colors.$grey-75};
    }
    p {
        font-family: 'Avenir', sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 164.01%;
        letter-spacing: 0.07em;
    }
    .step {
        text-align: start;
    }
    .counter {
        height: 15vh;
        display: flex;
        align-items: center;
        width: 275px;// to align to left
        .round {
            
            font-family: 'Avenir', sans-serif;
            font-size: 12px;
            
            border: 1.1735px solid var(--border-color);
            box-sizing: border-box;
            //box-shadow: 0px 14.082px 28.164px -4.69401px rgb(0 0 0 / 25%);
            border-radius: 6.082px;//50%;
            margin-right: 20px;
            position: relative;
            z-index: 2;
            height: 24px;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 12px;
            padding: 0; 
            // margin: 0;
            // align-content: center;
            // border-radius: 50%;
            // background: linear-gradient(27deg, rgba(173, 129, 12, 1) 29%, rgba(248, 215, 108, 1) 53%, rgba(160, 123, 20, 1) 81%);
        }
        .roundDark {
            background: #222B45;
            color: var(--text-color);
        }
        .roundLight {
            background: #{colors.$white};
            color: #{colors.$black};
        }
        // .line:before {
        //     color: var(--text-color);
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     z-index: 1;
        //     top: 100%;
        //     left: 50%;
        //     width: 1px;
        //     height: 90px;
        //     //background-image: linear-gradient(180deg, #ffffff00, #ffffff);
        //     border: 1.1735px solid var(--border-color);
        //     transform: rotate(180deg);
        // }
        .line:before {
            background:var(--border-color);
            color: var(--text-color);
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            top: 100%;  /* Adjusts the line to start from the middle of the circle vertically */
            left: 50%; /* Center the line horizontally */
            transform: translate(-50%, 0) rotate(180deg); /* Correctly centers the line and rotates it */
            width: 0;
            height: 90px;  /* Adjust the height as needed */
            border: 0.5px solid var(--border-color);
        }
    }
    
}

// mobile
@media only screen and (max-width: 767px) {
    .leftContainer {
        padding-left: 14%;
        .counter {
            height: 8vh;
            display: flex;
            align-items: center;
            width: 275px;// to align to left
            .round {
                
                font-family: 'Avenir', sans-serif;
                font-size: 12px;
                
                border: 1.1735px solid var(--border-color);
                box-sizing: border-box;
                //box-shadow: 0px 14.082px 28.164px -4.69401px rgb(0 0 0 / 25%);
                border-radius: 6.082px;//50%;
                margin-right: 20px;
                position: relative;
                z-index: 2;
                height: 24px;
                width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                line-height: 12px;
                padding: 0; 
                // margin: 0;
                // align-content: center;
                // border-radius: 50%;
                // background: linear-gradient(27deg, rgba(173, 129, 12, 1) 29%, rgba(248, 215, 108, 1) 53%, rgba(160, 123, 20, 1) 81%);
            }
            .roundDark {
                background: #222B45;
                color: var(--text-color);
            }
            .roundLight {
                background: #{colors.$white};
                color: #{colors.$black};
            }
            // .line:before {
            //     color: var(--text-color);
            //     content: "";
            //     display: block;
            //     position: absolute;
            //     z-index: 1;
            //     top: 100%;
            //     left: 50%;
            //     width: 1px;
            //     height: 90px;
            //     //background-image: linear-gradient(180deg, #ffffff00, #ffffff);
            //     border: 1.1735px solid var(--border-color);
            //     transform: rotate(180deg);
            // }
            .line:before {
                background:var(--border-color);
                color: var(--text-color);
                content: "";
                display: block;
                position: absolute;
                z-index: 1;
                top: 100%;  /* Adjusts the line to start from the middle of the circle vertically */
                left: 50%; /* Center the line horizontally */
                transform: translate(-50%, 0) rotate(180deg); /* Correctly centers the line and rotates it */
                width: 0;
                height: 40px;  /* Adjust the height as needed */
                border: 0.5px solid var(--border-color);
            }
        }
        
    }
}
// tablet
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .leftContainer {
        // background: var(--background-color);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 0;
        padding-left: 10%;
        
        // overflow: hidden;
        .content {
            display: flex;
            flex-direction: column;
    
            align-items: flex-start;
        }
        .headerImage {
            padding: 0 32px 15px 0;
            width: 380px;
            height: auto;
            margin-left: -100px;
        }
        .headerImageLight {
            padding: 0 32px 15px 0;
            width: 380px;
            height: auto;
            margin-left: -100px;
        }
        .header { 
            font-size: 32.858px;
            line-height: 101.51%; 
            width: 278px; 
            font-style: normal;
            font-family: 'Avenir', sans-serif;
            font-weight: 600;
            font-size: 26px;
            letter-spacing: normal;
            //text-transform: uppercase; 
            margin-top: 14px;
            margin-left: 6px;
            text-align: left;
        }
        .light {
            color: #{colors.$black};
        }
        .dark {
            color: #{colors.$white};
        }
        .secondHeader {
            width: 278px;
            font-style: normal;
            font-weight: normal;
            font-family: 'Avenir', sans-serif;
            font-size: 14px;
            line-height: 101.51%;
            // letter-spacing: 0.56em;
            letter-spacing: normal;
            // text-transform: uppercase;
            
            margin-top: 10px;
            margin-left: 6px;
            text-align: left;
        }
        .secondDark {
            color: rgba(255, 255, 255, 0.73);
        }
        .secondLight {
            color: #{colors.$grey-75};
        }
        p {
            font-family: 'Avenir', sans-serif;
            font-weight: normal;
            font-size: 14px;
            line-height: 164.01%;
            letter-spacing: 0.07em;
        }
        .step {
            text-align: start;
        }
        .counter {
            height: 15vh;
            display: flex;
            align-items: center;
            width: 275px;// to align to left
            .round {
                
                font-family: 'Avenir', sans-serif;
                font-size: 12px;
                
                border: 1.1735px solid var(--border-color);
                box-sizing: border-box;
                //box-shadow: 0px 14.082px 28.164px -4.69401px rgb(0 0 0 / 25%);
                border-radius: 6.082px;//50%;
                margin-right: 20px;
                position: relative;
                z-index: 2;
                height: 24px;
                width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                line-height: 12px;
                padding: 0; 
                // margin: 0;
                // align-content: center;
                // border-radius: 50%;
                // background: linear-gradient(27deg, rgba(173, 129, 12, 1) 29%, rgba(248, 215, 108, 1) 53%, rgba(160, 123, 20, 1) 81%);
            }
            .roundDark {
                background: #222B45;
                color: var(--text-color);
            }
            .roundLight {
                background: #{colors.$white};
                color: #{colors.$black};
            }
            // .line:before {
            //     color: var(--text-color);
            //     content: "";
            //     display: block;
            //     position: absolute;
            //     z-index: 1;
            //     top: 100%;
            //     left: 50%;
            //     width: 1px;
            //     height: 90px;
            //     //background-image: linear-gradient(180deg, #ffffff00, #ffffff);
            //     border: 1.1735px solid var(--border-color);
            //     transform: rotate(180deg);
            // }
            .line:before {
                background:var(--border-color);
                color: var(--text-color);
                content: "";
                display: block;
                position: absolute;
                z-index: 1;
                top: 100%;  /* Adjusts the line to start from the middle of the circle vertically */
                left: 50%; /* Center the line horizontally */
                transform: translate(-50%, 0) rotate(180deg); /* Correctly centers the line and rotates it */
                width: 0;
                height: 90px;  /* Adjust the height as needed */
                border: 0.5px solid var(--border-color);
            }
        }
        
    }
}